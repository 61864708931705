<template>
  <RaiSetup
    :title="$t('buy.setup.prewrittenSMS.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      {{ $t("buy.setup.prewrittenSMS.extension") }}
    </template>
    <RaiSetupGroup :title="$t('buy.setup.prewrittenSMS.prewrittenGroup.title')">
      <MessageList
        :store="store"
        :items="messages"
        @item-click="$emit('edit', $event)"
      />
      <div class="d-flex justify-end py-2">
        <v-menu
          top
          :nudge-left="40"
          :nudge-top="10"
          offset-y
          transition="slide-y-reverse-transition"
        >
          <template #activator="{ on, attrs }">
            <VxBtn v-bind="attrs" v-on="on">
              {{ $t("buy.setup.prewrittenSMS.prewrittenGroup.addNew") }}
            </VxBtn>
          </template>
          <v-list dense>
            <v-list-item @click="$emit('add-auto-reply')">
              <v-list-item-icon>
                <v-icon size="20">$reply</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("buy.setup.prewrittenSMS.prewrittenGroup.autoReply") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$emit('add-template')">
              <v-list-item-icon>
                <v-icon size="20">$message</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("buy.setup.prewrittenSMS.prewrittenGroup.template") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </RaiSetupGroup>
    <VxAlert type="info">
      <strong>{{ $t("buy.setup.prewrittenSMS.prewrittenGroup.note") }}</strong>
      {{ $t("buy.setup.prewrittenSMS.prewrittenGroup.noteContent") }}
      <RaiLink href="#">
        {{ $t("buy.setup.prewrittenSMS.prewrittenGroup.otherSuggestedLink") }}
      </RaiLink>
    </VxAlert>
  </RaiSetup>
</template>

<script>
import { MessageList } from "@/views/Texting/SetupView/components";
import { RaiSetup, RaiSetupGroup, RaiLink, VxAlert, VxBtn } from "@/core-ui";

export default {
  name: "PrewrittenSMSSetup",
  components: {
    RaiSetup,
    RaiSetupGroup,
    RaiLink,
    VxAlert,
    VxBtn,
    MessageList,
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
