<template>
  <RaiSetup
    :title="$t('buy.setup.passTakeResons.title')"
    :type="setupType"
    :error-message="reasonsMissingError"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      <p>
        {{ $t("buy.setup.passTakeResons.extension") }}
      </p>
      <p>
        {{ $t("buy.setup.passTakeResons.extension2") }}
      </p>
    </template>
    <div class="d-sm-flex justify-space-between">
      <div class="flex-grow-1">
        <ReasonsEditableList
          :title="$t('buy.setup.passTakeResons.takeReasonsList.title')"
          :reasons="editableTakeReasons"
          :editable="isEditable"
          :loading-reasons="loadingReasons"
          :empty-data-text="
            $t('buy.setup.passTakeResons.takeReasonsList.empty')
          "
          @update:reasons="onUpdateTakeReasons"
        />
      </div>
      <div class="flex-grow-1">
        <ReasonsEditableList
          :title="$t('buy.setup.passTakeResons.passReasonsList.title')"
          :reasons="editablePassReasons"
          :editable="isEditable"
          :loading-reasons="loadingReasons"
          :empty-data-text="
            $t('buy.setup.passTakeResons.passReasonsList.empty')
          "
          @update:reasons="onUpdatePassReasons"
        />
      </div>
    </div>

    <div class="d-flex justify-end">
      <VxBtn v-if="!isEditable" @click="isEditable = true">
        {{ $t("buy.setup.passTakeResons.edit") }}
      </VxBtn>
      <VxBtn v-if="isEditable" secondary @click="onCancel">
        {{ $t("buy.setup.passTakeResons.cancel") }}
      </VxBtn>
      <VxBtn v-if="isEditable" :loading="loadingReasons" @click="onSave">
        {{ $t("buy.setup.passTakeResons.save") }}
      </VxBtn>
    </div>
  </RaiSetup>
</template>

<script>
import { isEqual } from "lodash";
import { RaiSetup, SetupType, VxBtn } from "@/core-ui";
import ReasonsEditableList from "./ReasonsEditableList.vue";

export default {
  name: "PassAndTakeReasonSetup",
  components: {
    RaiSetup,
    ReasonsEditableList,
    VxBtn,
  },
  props: {
    takeReasons: {
      type: Array,
      default: () => [],
    },
    passReasons: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    isEditable: false,
    loadingReasons: false,
    editableTakeReasons: vm.takeReasons,
    editablePassReasons: vm.passReasons,
  }),
  computed: {
    setupType() {
      // If type specified from outside, use that
      if (this.type) {
        return this.type;
      }

      if (this.takeReasons.length <= 0 || this.passReasons.length <= 0) {
        return SetupType.WARNING;
      }

      return SetupType.PENDING;
    },
    reasonsMissingError() {
      const errors = [];

      if (this.takeReasons.length <= 0) {
        errors.push(this.$t("buy.setup.passTakeResons.noPassReasons"));
      }

      if (this.passReasons.length <= 0) {
        errors.push(this.$t("buy.setup.passTakeResons.noTakeReasons"));
      }

      if (errors.length <= 0) {
        return null;
      }

      return errors.join(" ");
    },
  },
  watch: {
    editable: {
      immediate: true,
      handler(value) {
        this.isEditable = value;
      },
    },

    takeReasons: {
      deep: true,
      handler(value) {
        // If reasons change while loading data, it means that the save to server was succefull,
        // and we can make this a non editable field again
        if (this.loadingReasons) {
          this.isEditable = false;
        }

        this.editableTakeReasons = value;

        this.loadingReasons = false;
      },
    },
    passReasons: {
      deep: true,
      handler(value) {
        // If reasons change while loading data, it means that the save to server was succefull,
        // and we can make this a non editable field again
        if (this.loadingReasons) {
          this.isEditable = false;
        }

        this.editablePassReasons = value;

        this.loadingReasons = false;
      },
    },
  },
  methods: {
    onUpdateTakeReasons(reasons) {
      this.editableTakeReasons = reasons;
    },
    onUpdatePassReasons(reasons) {
      this.editablePassReasons = reasons;
    },
    onCancel() {
      this.editableTakeReasons = this.takeReasons;
      this.editablePassReasons = this.passReasons;
      this.isEditable = false;
    },
    onSave() {
      const fitleredTakeReasons = this.editableTakeReasons.filter(
        (reason) => !!reason
      );
      const fitleredPassReasons = this.editablePassReasons.filter(
        (reason) => !!reason
      );

      if (
        !isEqual(this.takeReasons, fitleredTakeReasons) ||
        !isEqual(this.passReasons, fitleredPassReasons)
      ) {
        this.loadingReasons = true;
      } else {
        this.isEditable = false;
      }

      this.$emit("update:reasons", {
        takeReasons: fitleredTakeReasons,
        passReasons: fitleredPassReasons,
      });
    },
  },
};
</script>

<style>
.reasons-data-list .empty-loading-data-row .text-center {
  text-align: left !important;
  padding: 0px !important;
}
</style>
