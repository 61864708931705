<template>
  <v-hover v-slot="{ hover }">
    <v-list-item v-bind="props" @click="onClick">
      <v-list-item-content>
        <v-row no-gutters align="center">
          <v-col cols="12" md="3" lg="2">
            <v-list-item-title class="text-overline">
              {{ item.title }}
            </v-list-item-title>
          </v-col>
          <v-col cols="12" md="9" lg="10">
            <div class="d-flex align-center">
              <v-list-item-subtitle>
                <span
                  class="d-flex flex-shrink-1"
                  :class="{ 'text-wrap': isDesktop }"
                >
                  <slot name="content" v-bind="item">
                    {{ item.value }}
                  </slot>
                </span>
              </v-list-item-subtitle>
              <v-chip
                v-if="item.isAutoReply && isDesktop"
                class="flex-shrink-0"
                small
              >
                <v-icon x-small left>$reply</v-icon>
                <span class="text-uppercase"> Auto response </span>
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-list-item-content>
      <v-list-item-action>
        <VxTooltip v-if="hasTooltip" type="info" bottom>
          {{ item.tooltip }}
        </VxTooltip>
        <v-fade-transition v-else>
          <v-icon v-if="hover && !readonly">$edit</v-icon>
          <v-icon v-else-if="item.isAutoReply && isMobile" small>$reply</v-icon>
        </v-fade-transition>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { VxTooltip, useResponsiveness } from "@/core-ui";

export default {
  name: "MessageListItem",
  components: { VxTooltip },
  mixins: [useResponsiveness()],
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        id: 0,
        title: "",
        value: "",
        tooltip: "",
        isAutoReply: false,
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTooltip() {
      return this.item.tooltip && this.item.tooltip.trim() !== "";
    },
    props() {
      return {
        inactive: this.readonly,
        ripple: !this.readonly,
        ...this.$attrs,
      };
    },
  },
  methods: {
    onClick() {
      if (this.readonly) return;
      this.$emit("click", this.item);
    },
  },
};
</script>
