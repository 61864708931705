// ### POLICY ###
export * from "./LegaleseQuery.gql";
export * from "./LegaleseUpdate.gql";

// ### CUSTOMER REQUIREMENTS ###
export * from "./EmailRequiredUpdate.gql";
export * from "./LicenseRequiredUpdate.gql";
export * from "./RequirementsQuery.gql";

// ### EMPLOYEES ###
export * from "./EmployeesQuery.gql";
export * from "./DrsEmployeesQuery.gql";
export * from "./EmployeeUpdate.gql";

// ### TAKE/PASS REASONS ###
export * from "./ReasonsQuery.gql";
export * from "./ReasonsUpdate.gql";

export * from "./GenerateQRCode.gql";
