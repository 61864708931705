import Vue from "vue";
import { isNumber, isString } from "lodash";

/**
 * @typedef {Object} RouterQueryOptions
 * @property {String} name - the name of router URL query
 * @property {String|Number} [initialValue] - the initial value to be set if query is not defined
 * @property {Boolean} [preserveValue = false] - should preserve value as it is without any conversions, defaults to false
 * @property {Boolean} [preserveQuery = true] - should persist query after location change, defaults to be true
 */

/**
 * Define router query as prop and update it dynamically via computed prop
 * @param {RouterQueryOptions} options - the options for setting query usage
 * @returns prop and computed for given query name
 * @example
 * ...
 * mixins: [useRouterQuery({ name: "active" })], // -> creates "active" prop and "activeQuery" computed
 * ...
 */
export function useRouterQuery(options) {
  const {
    name,
    initialValue,
    preserveValue = false,
    preserveQuery = true,
  } = options;

  const computedQueryName = `${name}Query`;

  return Vue.extend({
    props: {
      [name]: {
        type: [String, Number],
        default: null,
      },
    },
    computed: {
      [computedQueryName]: {
        get() {
          const value = this[name] ?? this.$route.query[name];
          return Number.isNaN(value) || preserveValue
            ? value
            : parseInt(value, 10);
        },
        set(value) {
          if (name in this.$route.query && value === this.$route.query[name])
            return;

          this.$router.replace({
            name: this.$route.name,
            query: { [name]: value },
            preserveQuery,
          });
        },
      },
    },
    created() {
      const hasValidInitialValue =
        isString(initialValue) || isNumber(initialValue);

      if (hasValidInitialValue && !this[computedQueryName]) {
        this[computedQueryName] = initialValue;
      }
    },
  });
}
