<template>
  <RaiSetup
    :title="$t('buy.setup.linkEmployeesToDrs.title')"
    :type="setupType"
    :error-message="errorMessage"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      <p>
        {{ $t("buy.setup.linkEmployeesToDrs.extension") }}
      </p>
      <p>{{ $t("buy.setup.linkEmployeesToDrs.extension2") }}</p>
    </template>
    <RaiSetupGroup
      v-if="hasEmployeesNotLinkedToDRS"
      :title="$t('buy.setup.linkEmployeesToDrs.reviewTeamGroup.title')"
    >
      <p
        v-html="$t('buy.setup.linkEmployeesToDrs.reviewTeamGroup.description')"
      />
      <EmployeeDRSDataList
        :items="employeesNotLinkedToDRS"
        :drs-employees="drsEmployees"
        @change="updateEmployee"
      />
    </RaiSetupGroup>

    <RaiSetupGroup
      :title="$t('buy.setup.linkEmployeesToDrs.buyTeamGroup.title')"
    >
      <p>{{ $t("buy.setup.linkEmployeesToDrs.buyTeamGroup.description") }}</p>
      <EmployeeDRSDataList
        :items="employeesLinkedToDRS"
        :drs-employees="drsEmployees"
        @change="updateEmployee"
      />
    </RaiSetupGroup>
    <VxAlert type="info">
      <strong>{{ $t("buy.setup.linkEmployeesToDrs.note") }}</strong>
      {{ $t("buy.setup.linkEmployeesToDrs.noteContent") }}
      <RaiLink href="#">
        {{ $t("buy.setup.linkEmployeesToDrs.syncDRSLink") }}
      </RaiLink>
    </VxAlert>
  </RaiSetup>
</template>

<script>
import EmployeeDRSDataList from "./EmployeeDRSDataList.vue";
import {
  RaiSetup,
  RaiSetupGroup,
  VxAlert,
  SetupType,
  RaiLink,
} from "@/core-ui";

export default {
  name: "EmployeeDRSSetup",
  components: {
    EmployeeDRSDataList,
    RaiSetup,
    RaiSetupGroup,
    VxAlert,
    RaiLink,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default: () => [],
    },
    drsEmployees: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    setupType: null,
  }),
  computed: {
    employeesLinkedToDRS() {
      return this.employees.filter((x) => x.drsEmployeeCode);
    },
    errorMessage() {
      return this.hasEmployeesNotLinkedToDRS
        ? this.$tc(
            "buy.setup.linkEmployeesToDrs.notLinkedToDrsError",
            this.employeesNotLinkedToDRS.length
          )
        : "";
    },
    hasEmployeesNotLinkedToDRS() {
      return this.employeesNotLinkedToDRS.length > 0;
    },
    employeesNotLinkedToDRS() {
      return this.employees.filter((x) => !x.drsEmployeeCode);
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(value) {
        this.setupType = value;
      },
    },
    hasNotLinkedToDRS: {
      immediate: true,
      handler(value) {
        this.setupType = value === true ? SetupType.WARNING : this.type;
      },
    },
  },
  methods: {
    updateEmployee(employee) {
      this.$emit("update:employee", employee);
    },
  },
};
</script>
