<template>
  <RaiSetup
    :title="$t('buy.setup.generateQRCode.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <VxForm v-slot="{ submitting }" @submit="$emit('generate', $event)">
      <v-row>
        <v-col cols="12" md="6" xl="3">
          <VxTextField
            v-model="form.email"
            label="Email"
            placeholder="eg. john.doe@example.com"
            name="email"
            type="email"
            rules="required|email"
          />
        </v-col>
        <v-col>
          <VxBtn class="mt-md-2" :loading="submitting" type="submit">
            Generate
          </VxBtn>
        </v-col>
      </v-row>
    </VxForm>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxForm, VxTextField, VxBtn } from "@/core-ui";

export default {
  name: "GenerateQRCodeSetup",
  components: { RaiSetup, VxForm, VxTextField, VxBtn },
  data: () => ({
    form: {
      email: "",
    },
  }),
  created() {
    this.form.email = this.$store.getters["auth/isPosUser"]
      ? this.$store.state.teamMode.employee.email
      : this.$store.state.auth.email;
  },
};
</script>
