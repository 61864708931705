<template>
  <RaiSetupGroup :title="title">
    <div v-if="editable" class="mr-2">
      <VxTextField
        v-for="(reason, index) in editableReasons"
        :key="index"
        :value="editableReasons[index]"
        clearable
        placeholder="Add a reason"
        :disabled="loadingReasons"
        @input="updateReason(index, $event)"
      >
      </VxTextField>
    </div>

    <VxDataList
      v-if="!editable"
      class="reasons-data-list"
      :empty-data-text="emptyDataText"
    >
      <template #body>
        <VxDataListItem v-for="(reason, index) in reasons" :key="index">
          <template #desktop>
            <td>
              {{ reason }}
            </td>
          </template>
          <template #mobile>
            <v-list-item-content>
              <v-list-item-title>
                {{ reason }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </VxDataListItem>
      </template>
    </VxDataList>
  </RaiSetupGroup>
</template>

<script>
const REASONS_LIMIT = 5;

import {
  RaiSetupGroup,
  VxDataList,
  VxDataListItem,
  VxTextField,
} from "@/core-ui";

export default {
  name: "ReasonsEditableList",
  components: {
    RaiSetupGroup,
    VxDataList,
    VxDataListItem,
    VxTextField,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    emptyDataText: {
      type: String,
      required: true,
    },
    reasons: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    loadingReasons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    editableReasons() {
      const reasonsLimited = [];

      // First pick up to REASONS_LIMIT (5) items from reasons
      for (
        let index = 0;
        index < this.reasons.length && index < REASONS_LIMIT;
        index++
      ) {
        reasonsLimited.push(this.reasons[index]);
      }

      // Fill up to REASONS_LIMIT (5) items with empty items
      while (reasonsLimited.length < REASONS_LIMIT) {
        reasonsLimited.push("");
      }

      return reasonsLimited;
    },
  },
  methods: {
    updateReason(reasonIndex, value) {
      const reasons = this.editableReasons;
      reasons[reasonIndex] = value;
      this.$emit("update:reasons", reasons);
    },
  },
};
</script>

<style>
.reasons-data-list .empty-loading-data-row .text-center {
  text-align: left !important;
  padding: 0px !important;
}
</style>
