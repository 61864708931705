<template>
  <!-- TODO: implement VxDataList to have empty state -->
  <v-list>
    <MessageListItem v-if="hasStore" :item="defaultStoreMessage" readonly />
    <template v-if="items.length > 0 || hasStore">
      <MessageListItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        :readonly="readonly"
        @click="onItemClick"
      />
    </template>
    <div v-else class="d-flex justify-center">
      <span class="text--secondary">No messages</span>
    </div>
  </v-list>
</template>

<script>
import MessageListItem from "./MessageListItem.vue";

export default {
  name: "MessageList",
  components: { MessageListItem },
  props: {
    store: {
      type: Object,
      default: () => ({
        id: 0,
        formalName: "",
      }),
    },
    items: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasStore() {
      return this.store && this.store.id > 0;
    },
    defaultStoreMessage() {
      return {
        id: -1,
        title: "Buy check-in",
        value: `Complete your mobile check-in at ${this.store?.formalName}`,
        tooltip:
          "This is the text message customers see at check-in. It is set by ResaleAI and can't be edited.",
      };
    },
  },
  methods: {
    onItemClick(item) {
      this.$emit("item-click", item);
    },
  },
};
</script>
