<template>
  <RaiSetup title="Message templates" v-bind="$attrs" v-on="$listeners">
    <template #extension>
      Set up quick messages to easily communicate with customers
    </template>
    <MessageList
      :store="store"
      :items="messages"
      @item-click="$emit('edit', $event)"
    />
    <VxFormActions>
      <VxBtn @click="$emit('add')">Add new</VxBtn>
    </VxFormActions>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxFormActions, VxBtn } from "@/core-ui";
import MessageList from "../MessageList/MessageList.vue";

export default {
  name: "TemplateSetup",
  components: {
    RaiSetup,
    MessageList,
    VxFormActions,
    VxBtn,
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
