<template>
  <RaiSetup
    :title="$t('buy.setup.posSelect.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      {{ $t("buy.setup.posSelect.extension") }}
    </template>
    <PrinterSelect />
  </RaiSetup>
</template>

<script>
import { RaiSetup } from "@/core-ui";
import PrinterSelect from "@/components/pos/PrinterSelect.vue";

export default {
  name: "PosSetup",
  components: { RaiSetup, PrinterSelect },
};
</script>
