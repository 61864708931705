<template>
  <VxDataList :headers="headers">
    <template #body>
      <VxDataListItem v-for="employee in items" :key="employee.id">
        <template #desktop>
          <td>{{ employee.fullName }}</td>
          <td>
            <v-autocomplete
              dense
              outlined
              hide-details
              :no-data-text="
                $t(
                  'buy.setup.linkEmployeesToDrs.notLikedList.noSyncedEmployees'
                )
              "
              :items="drsEmployees"
              :value="employee.drsEmployeeCode"
              @change="onDRSCodeChange(employee.id, $event)"
            />
          </td>
        </template>
        <template #mobile>
          <v-list-item-content>
            <v-list-item-title class="mb-2">
              {{ employee.fullName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-autocomplete
                dense
                outlined
                hide-details
                :no-data-text="
                  $t(
                    'buy.setup.linkEmployeesToDrs.notLikedList.noSyncedEmployees'
                  )
                "
                :items="drsEmployees"
                :value="employee.drsEmployeeCode"
                @change="onDRSCodeChange(employee.id, $event)"
              />
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </VxDataListItem>
    </template>
  </VxDataList>
</template>

<script>
import { VxDataList, VxDataListItem } from "@/core-ui";

export default {
  name: "EmployeeDRSDataList",
  components: {
    VxDataList,
    VxDataListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    drsEmployees: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          propertyPath: "fullName",
          label: "Name",
        },
        {
          propertyPath: "drsEmployeeCode",
          label: "DRS Employee",
        },
      ];
    },
  },
  methods: {
    onDRSCodeChange(employeeId, drsCode) {
      this.$emit("change", {
        employee: { id: employeeId, drsEmployeeCode: drsCode },
      });
    },
  },
};
</script>
