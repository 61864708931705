<template>
  <RaiSetup
    :title="$t('buy.setup.policySetup.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      {{ $t("buy.setup.policySetup.extension") }}
    </template>
    <RaiSetupGroup :title="$t('buy.setup.policySetup.termGroup.title')">
      <p>{{ $t("buy.setup.policySetup.termGroup.description1") }}</p>
      <p>
        {{ $t("buy.setup.policySetup.termGroup.description2") }}
        <span class="font-italic text--secondary">
          {{ $t("buy.setup.policySetup.termGroup.defaultPolicy") }}
        </span>
      </p>
      <RaiInlineEdit
        v-model="isEditable"
        :text.sync="policyValue"
        :readonly="!computedActiveEmployeeCan.actAsManager"
        :placeholder="
          $t('buy.setup.policySetup.termGroup.editablePolicyPlaceholder')
        "
      >
        <template #input="{ ref, on, attrs }">
          <VxTextarea :ref="ref" v-bind="attrs" v-on="on" />
        </template>
      </RaiInlineEdit>
    </RaiSetupGroup>
    <VxAlert type="info">
      <strong>{{ $t("buy.setup.policySetup.note") }}</strong>
      {{ $t("buy.setup.policySetup.noteContent") }}
    </VxAlert>
  </RaiSetup>
</template>

<script>
import { hasActiveEmployeeAccess } from "@/mixins/employee";
import {
  VxAlert,
  VxTextarea,
  RaiSetup,
  RaiSetupGroup,
  RaiInlineEdit,
} from "@/core-ui";

export default {
  name: "PolicySetup",
  components: { VxAlert, VxTextarea, RaiSetup, RaiSetupGroup, RaiInlineEdit },
  mixins: [hasActiveEmployeeAccess],
  props: {
    policy: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEditable: false,
  }),
  computed: {
    policyValue: {
      get() {
        return this.policy;
      },
      set(value) {
        this.$emit("update:policy", value);
      },
    },
  },
  watch: {
    editable: {
      immediate: true,
      handler(value) {
        this.isEditable = value;
      },
    },
  },
};
</script>
