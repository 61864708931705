<template>
  <TextWithLinksStep
    :title="$t('buy.setup.scannerSetup.title')"
    :sections="sections"
    v-bind="$props"
    :loading="loading"
    :hide-next="false"
    v-on="$listeners"
  >
    <template #extension>
      <p>
        {{ $t("buy.setup.scannerSetup.description") }}
      </p>
      <p>
        {{ $t("buy.setup.scannerSetup.description2") }}
      </p>
    </template>
  </TextWithLinksStep>
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep";

export default {
  name: "YourScannerSetup",
  components: { TextWithLinksStep },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sections() {
      return [
        {
          title: this.$t("buy.setup.scannerSetup.step1.title"),
          description: this.$t("buy.setup.scannerSetup.step1.description"),
          goTo: {
            text: this.$t("buy.setup.scannerSetup.step1.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045041-what-are-the-benefits-of-the-pos-scanner-and-the-rai-flex-device-which-do-i-need-",
          },
        },
        {
          title: this.$t("buy.setup.scannerSetup.step2.title"),
          description: this.$t("buy.setup.scannerSetup.step2.description"),
          goTo: {
            text: this.$t("buy.setup.scannerSetup.step2.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000044991-i-just-received-my-flex-how-do-i-set-it-up-",
          },
        },
        {
          title: this.$t("buy.setup.scannerSetup.step3.title"),
          description: this.$t("buy.setup.scannerSetup.step3.description"),
          goTo: {
            text: "Get started at the POS",
            href: "https://help.resaleai.com/support/solutions/articles/154000045065-how-do-i-set-up-my-new-pos-scanner-",
          },
        },
      ];
    },
  },
};
</script>
