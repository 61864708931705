<template>
  <RaiSetup title="Your information" v-bind="$attrs" v-on="$listeners">
    <template #extension>
      ResaleAI creates a new phone number for you that is used to send text
      messages. If a customer calls this phone number, it will route to your
      main store phone, so your team can assist.
    </template>
    <v-list>
      <MessageListItem :item="callAndTextNumber" readonly />
      <!-- TODO: refactor after VxPhoneField refactor -->
      <MessageListItem
        :item="landlineNumber"
        :ripple="!editLandlineNumber"
        @click="onLandlineNumberClick"
      >
        <template #content="{ value }">
          <template v-if="editLandlineNumber">
            <VxForm ref="form" :outlined="false" @submit="handleSubmit">
              <VxPhoneField
                ref="landlineField"
                v-model="innerLandlineNumber"
                :loading="loadingLandlineNumber"
                autocomplete="off"
                name="Phone"
                rules="required|na_phone"
                label="Phone number"
                @blur="handleBlur"
              />
            </VxForm>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </template>
      </MessageListItem>
    </v-list>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxPhoneField, VxForm } from "@/core-ui";
import MessageListItem from "../MessageList/MessageListItem.vue";

export default {
  name: "InfoSetup",
  components: { RaiSetup, VxPhoneField, MessageListItem, VxForm },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingLandlineNumber: false,
    editLandlineNumber: false,
    innerLandlineNumber: "",
  }),
  computed: {
    callAndTextNumber() {
      return {
        title: "Call & text number",
        value: this.store?.callAndTextNumber,
        tooltip:
          "This is the phone number used to send text messages. It is set by ResaleAI and can't be edited.",
      };
    },
    landlineNumber() {
      return {
        title: "Main store number",
        value: this.store?.landlineNumber,
      };
    },
  },
  watch: {
    "store.landlineNumber": {
      immediate: true,
      handler(value) {
        this.innerLandlineNumber = value;
      },
    },
  },
  methods: {
    resolveLandlineNumber() {
      this.editLandlineNumber = false;
      this.loadingLandlineNumber = false;
    },
    onLandlineNumberClick() {
      this.editLandlineNumber = true;
      this.$nextTick(() => this.$refs.landlineField.focus());
    },
    onLandlineNumberBlur() {
      this.loadingLandlineNumber = true;

      this.$emit("edit-landline-number", {
        landlineNumber: this.innerLandlineNumber,
        resolve: this.resolveLandlineNumber,
      });

      if (!this.$listeners["edit-landline-number"]) {
        this.resolveLandlineNumber();
      }
    },
    handleSubmit() {
      this.onLandlineNumberBlur();
    },
    handleBlur() {
      this.$refs.form.submit();
    },
  },
};
</script>
