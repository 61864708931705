<template>
  <RaiSetup title="Auto-response messages" v-bind="$attrs" v-on="$listeners">
    <template #extension>
      Set up a shortcode, and ResaleAI will send an automatic response any time
      a customer texts that code to your call & text number.
    </template>
    <MessageList :items="messages" @item-click="$emit('edit', $event)" />
    <VxFormActions>
      <VxBtn @click="$emit('add')">Add new</VxBtn>
    </VxFormActions>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxFormActions, VxBtn } from "@/core-ui";
import MessageList from "../MessageList/MessageList.vue";

export default {
  name: "AutoResponseSetup",
  components: {
    RaiSetup,
    MessageList,
    VxFormActions,
    VxBtn,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
