<template>
  <RaiSetup
    :title="$t('buy.setup.customerRequirement.title')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #extension>
      {{ $t("buy.setup.customerRequirement.extension") }}
    </template>
    <RaiSetupToggle
      v-model="form.emailAddress"
      :loading="emailAddressLoading"
      :title="$t('buy.setup.customerRequirement.emailAddressTitle')"
      :subtitle="$t('buy.setup.customerRequirement.emailAddressSubTitle')"
    />
    <RaiSetupToggle
      v-model="form.license"
      :loading="licenseLoading"
      :title="$t('buy.setup.customerRequirement.idNumberTitle')"
      :subtitle="$t('buy.setup.customerRequirement.idNumberSubTitle')"
    />
    <VxAlert type="info">
      <strong>{{ $t("buy.setup.customerRequirement.note") }}</strong>
      {{ $t("buy.setup.customerRequirement.noteContent") }}
      <RaiLink
        href="https://help.resaleai.com/support/solutions/articles/154000045041-what-are-the-benefits-of-the-pos-scanner-and-the-rai-flex-device-which-do-i-need-"
        external
      >
        {{ $t("buy.setup.customerRequirement.learnMoreLink") }}
      </RaiLink>
    </VxAlert>
  </RaiSetup>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import { VxAlert, RaiSetup, RaiSetupToggle, RaiLink } from "@/core-ui";

export default {
  name: "CustomerRequirementSetup",
  components: { VxAlert, RaiSetup, RaiSetupToggle, RaiLink },
  mixins: [
    useFormObject({
      prop: "requirements",
      sync: true,
      default: {
        emailAddress: null,
        license: null,
      },
      handler() {
        this.emailAddressLoading = false;
        this.licenseLoading = false;
      },
    }),
  ],
  data: () => ({
    licenseLoading: false,
    emailAddressLoading: false,
  }),
  watch: {
    "form.emailAddress": {
      handler(value) {
        if (value !== this.requirements.emailAddress) {
          this.emailAddressLoading = true;
        }
      },
    },
    "form.license": {
      handler(value) {
        if (value !== this.requirements.license) {
          this.licenseLoading = true;
        }
      },
    },
  },
};
</script>
