<template>
  <TextWithLinksStep
    :title="$t('buy.setup.trainTeam.title')"
    :sections="sections"
    v-bind="$props"
    :loading="loading"
  >
    <template #extension>
      {{ $t("buy.setup.trainTeam.extension") }}
    </template>
  </TextWithLinksStep>
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep";

export default {
  name: "TrainTeamSetup",
  components: { TextWithLinksStep },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sections() {
      return [
        {
          title: this.$t("buy.setup.trainTeam.step1.title"),
          description: this.$t("buy.setup.trainTeam.step1.description"),
          goTo: {
            text: this.$t("buy.setup.trainTeam.step2.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045089-i-have-never-used-sign-to-sell-before-what-do-i-need-to-know-",
          },
        },
        {
          title: this.$t("buy.setup.trainTeam.step2.title"),
          description: this.$t("buy.setup.trainTeam.step2.description"),
          goTo: {
            text: this.$t("buy.setup.trainTeam.step2.linkText"),
            /* TODO: Issue #1403 */ href: "https://resaleai.zendesk.com/hc/en-us/articles/360034921371-How-do-I-check-in-a-buy-on-version-3-0-General-buy-FAQ-",
          },
        },
      ];
    },
  },
};
</script>
